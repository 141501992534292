import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { TrueviewsDialogComponent } from '@components/trueviews-dialog/trueviews-dialog.component';
import { VP01DialogComponent } from '@app/components/viewpoints-dialogs/viewpoint01-dialog.component';
import { Viewpoint11DialogComponent } from '@app/components/viewpoints-dialogs/viewpoint11-dialog.component';

import { of, Observable } from 'rxjs';
import Sites from '@documents/sites.document'
import Site from "@models/site.model"

// private sites: Observable<Site[]> = of(Sites)

@Injectable({
  providedIn: 'root'
})
export class SitesService {

  constructor(
    private dialog: MatDialog) {
  }

  private sitesAsObservables(): Observable<Site[]> {
    return of(Sites)
  }

  public getSites(): Observable<Site[]> {
    const sites = this.sitesAsObservables()
    return sites
  }

  private VP01(): void {
    this.dialog.open(VP01DialogComponent)
  }

  private VP11(site: Site) {
    this.dialog.open(Viewpoint11DialogComponent, {
      data: site,
      id: 'viewpoint11-dialog'
    })
  }

  public openSiteTrueviewsDialog(site: Site): void {
    switch (site.id) {
      case 'VP01':
        this.VP01()
        break
      case 'VP11':
        this.VP11(site)
        break
      default:
        const dialogRef = this.dialog.open(TrueviewsDialogComponent, {
          data: site,
          id: 'trueview-comparison-dialog',
          height: 'auto',
          width: '95vw',
        });
    }
  }
}

