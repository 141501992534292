<mat-button-toggle-group *ngIf="!viewpoint11" value="existing" class="before-after-toggle-button" #group="matButtonToggleGroup">
  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints.galvanized.value}}" (click)="viewer.goToPage(1);showSnakbar(site.viewpoints.galvanized.text)">
    {{site.viewpoints.galvanized.text | titlecase }}
  </mat-button-toggle>
  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints.weathered.value}}" (click)="viewer.goToPage(2);showSnakbar(site.viewpoints.weathered.text)">
    {{site.viewpoints.weathered.text | titlecase }}
  </mat-button-toggle>
  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints.existing.value}}" (click)="viewer.goToPage(0);showSnakbar(site.viewpoints.existing.text)">
    {{site.viewpoints.existing.text | titlecase }}
  </mat-button-toggle>
</mat-button-toggle-group>


<mat-button-toggle-group *ngIf="viewpoint11" value="existing" class="before-after-toggle-button" #group="matButtonToggleGroup">

  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints['240_69kv'].weathered.value}}" (click)="viewer.goToPage(4);showSnakbar(site.viewpoints['240_69kv'].weathered.text)">
    {{site.viewpoints['240_69kv'].weathered.text | titlecase }}
  </mat-button-toggle>

  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints['240_69kv'].galvanized.value}}" (click)="viewer.goToPage(3);showSnakbar(site.viewpoints['240_69kv'].galvanized.text)">
    {{site.viewpoints['240_69kv'].galvanized.text | titlecase }}
  </mat-button-toggle>

  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints['69kv'].weathered.value}}" (click)="viewer.goToPage(2);showSnakbar(site.viewpoints['69kv'].weathered.text)">
    {{site.viewpoints['69kv'].weathered.text | titlecase }}
  </mat-button-toggle>

  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints['69kv'].galvanized.value}}" (click)="viewer.goToPage(1);showSnakbar(site.viewpoints['69kv'].galvanized.text)">
    {{site.viewpoints['69kv'].galvanized.text | titlecase }}
  </mat-button-toggle>

  <mat-button-toggle class="mat-button-toggle-zoom" value="{{site.viewpoints.existing.value}}" (click)="viewer.goToPage(0);showSnakbar(site.viewpoints.existing.text)">
    {{site.viewpoints.existing.text | titlecase }}
  </mat-button-toggle>

</mat-button-toggle-group>
