<div routerLinkActive="active" routerLink="/" matTooltip="Homepage">
  <fa-icon [icon]="faHome" size="1x"></fa-icon>
  <span> home</span>
</div>

<div (click)="calibrateScreen()">
  <ng-content *ngIf="isScreenCalibrated; then calibrationIcon else exclamationIcon"></ng-content>

  <ng-template #calibrationIcon>
    <div matTooltip="Screen calibration wizard">
      <fa-icon [icon]="faTv" size="1x"></fa-icon>
      <span> screen calibration</span>
    </div>
  </ng-template>

  <ng-template #exclamationIcon>
    <div matTooltip="This screen calibration wizard will step you through calibrating your screen for the best possible experience when viewing trueviews.">
      <fa-icon [icon]="faExclamationTriangle" size="1x"></fa-icon>
      <span> screen calibration</span>
    </div>

  </ng-template>
</div>

<div matTooltip="Reset map state" (click)="resetView.emit()">
  <fa-icon [icon]="faUndo" size="1x"></fa-icon>
  <span> reset view</span>
</div>
