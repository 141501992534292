const Sites = [{
  "id": "VP01",
  "name": "Viewpoint 01",
  "title": "Township Road 92 - Wildcat Creek Valley (No Longer Proposed)",
  "subtitle": "Looking Southwest",
  "lat": 49.728031706404053,
  "lng": -114.227572823495976,
  "orientation": 225,
  "icon": "../../assets/icons/viewpoint1-cone.png",
  "data": [
    "Easting Position(10TM): 55646.7 ",
    "Northing Position(10TM): 5506474.7",
    "Elevation of Viewpoint Position(NAD83): 1398.0",
    "Height of Camera Above Ground(m): 1.7",
    "Date of Photography: 19 February 2019 at 11:19 AM",
    "orientation of View: SW",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°"
  ],
  "viewpoints": null
}, {
  "id": "VP02",
  "name": "Viewpoint 02",
  "title": "Chapel Rock Road (Twp Rd. 85A)",
  "subtitle": "Looking Southwest",
  "lat": 49.698928,
  "lng": -114.263161,
  "orientation": 225,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position (10TM): 53302.8",
    "Northing Position (10TM): 5503197.8",
    "Elevation of Viewpoint Position (NAD83): 1446.0",
    "Height of Camera Above Ground (m): 1.7",
    "Date of Photography: 2 August 2019 at 01:37 PM",
    "orientation of View: SW",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°"
  ],
  "viewpoints": {
    "existing": {
      "id": "f9d873c6a3",
      "value": "existing",
      "text": "existing"
    },
    "galvanized": {
      "id": "dcce6a18fd",
      "value": "galvanized",
      "text": "galvanized"
    },
    "weathered": {
      "id": "abcefca041",
      "value": "weathered",
      "text": "brown finish"
    }
  }
}, {
  "id": "VP03",
  "name": "Viewpoint 03",
  "title": "Highway 22 (Cowboy Trail)",
  "subtitle": "Looking North",
  "lat": 49.690793,
  "lng": -114.181738,
  "orientation": 0,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position (10TM): 58985.0",
    "Northing Position (10TM): 5503449.9",
    "Elevation of Viewpoint Position (NAD83): 1267.0",
    "Height of Camera Above Ground (m): 1.7",
    "Date of Photography: 2 August 2019 at 01:51 PM",
    "orientation of View: N",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°",
  ],
  "viewpoints": {
    "existing": {
      "id": "59d87792bc",
      "value": "existing",
      "text": "existing"
    },
    "galvanized": {
      "id": "11517d7873",
      "value": "galvanized",
      "text": "galvanized"
    },
    "weathered": {
      "id": "bfa6c7be48",
      "value": "weathered",
      "text": "brown finish"
    }
  }
}, {
  "id": "VP04",
  "name": "Viewpoint 04",
  "title": "Range Road 15",
  "subtitle": "Looking South",
  "lat": 49.665617,
  "lng": -114.113883,
  "orientation": 180,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position (10TM): 63941.3",
    "Northing Position (10TM): 5498445.2",
    "Elevation of Viewpoint Position (NAD83): 1213.0",
    "Height of Camera Above Ground (m): 1.7",
    "Date of Photography: 19 February 2019 at 03:04 PM",
    "orientation of View: S",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°",
  ],
  "viewpoints": {
    "existing": {
      "id": "6164415ec3",
      "value": "existing",
      "text": "existing"
    },
    "galvanized": {
      "id": "bb6790bd87",
      "value": "galvanized",
      "text": "galvanized"
    },
    "weathered": {
      "id": "fcff472d81",
      "value": "weathered",
      "text": "brown finish"
    }
  }
}, {
  "id": "VP05",
  "name": "Viewpoint 05",
  "title": "Valley Rdg Road",
  "subtitle": "Looking Northeast",
  "lat": 49.616963712028564,
  "lng": -114.387947455528831,
  "orientation": 45,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position (10TM): 44193.7",
    "Northing Position (10TM): 5494024.9",
    "Elevation of Viewpoint Position (NAD83): 1430.0",
    "Height of Camera Above Ground (m): 1.7",
    "Date of Photography: 19 February 2019 at 01:50 PM",
    "orientation of View: NE",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°",
  ],
  "viewpoints": {
    "existing": {
      "id": "2c37d7b014",
      "value": "existing",
      "text": "existing"
    },
    "galvanized": {
      "id": "31f38e502c",
      "value": "galvanized",
      "text": "galvanized"
    },
    "weathered": {
      "id": "4d2ddaafe3",
      "value": "weathered",
      "text": "brown finish"
    }
  }
}, {
  "id": "VP06",
  "name": "Viewpoint 06",
  "title": "Highway 507",
  "subtitle": "Looking Northwest",
  "lat": 49.543699309365934,
  "lng": -114.232677531433126,
  "orientation": 315,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position (10TM): 55488.0",
    "Northing Position (10TM): 5485985.8",
    "Elevation of Viewpoint Position (NAD83): 1250.0",
    "Height of Camera Above Ground (m): 1.7",
    "Date of Photography: 19 February 2019 at 02:28 PM",
    "orientation of View: NW",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°",
  ],
  "viewpoints": {
    "existing": {
      "id": "b0c3b1631f",
      "value": "existing",
      "text": "existing"
    },
    "galvanized": {
      "id": "031e16ebfc",
      "value": "galvanized",
      "text": "galvanized"
    },
    "weathered": {
      "id": "03ea4ae126",
      "value": "weathered",
      "text": "brown finish"
    }
  }
}, {
  "id": "VP07",
  "name": "Viewpoint 07",
  "title": "Highway 3",
  "subtitle": "Looking South",
  "lat": 49.564833,
  "lng": -114.069944,
  "orientation": 225,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position (10TM): 67261.5",
    "Northing Position (10TM): 5488388.8",
    "Elevation of Viewpoint Position (NAD83): 1174.0",
    "Height of Camera Above Ground (m): 1.7",
    "Date of Photography:  2 August 2019 at 10:32 AM",
    "orientation of View: SW",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°",
  ],
  "viewpoints": {
    "existing": {
      "id": "3834591408",
      "value": "existing",
      "text": "existing"
    },
    "galvanized": {
      "id": "4c5e7cb3f0",
      "value": "galvanaized",
      "text": "galvanized"
    },
    "weathered": {
      "id": "eaac296541",
      "value": "weathered",
      "text": "brown finish"
    }
  }
}, {
  "id": "VP08",
  "name": "Viewpoint 08",
  "title": "Railway Ave",
  "subtitle": "Looking Northwest",
  "lat": 49.571250,
  "lng": -114.074889,
  "orientation": 0,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position (10TM): 67282.6",
    "Northing Position (10TM): 5488783.0",
    "Elevation of Viewpoint Position (NAD83): 1175.0",
    "Height of Camera Above Ground (m): 1.7",
    "Date of Photography: 19 Feburary 2019 at 03:29 PM",
    "orientation of View: N",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°",
  ],
  "viewpoints": {
    "existing": {
      "id": "84a29623b0",
      "value": "existing",
      "text": "existing"
    },
    "galvanized": {
      "id": "c0f5425ac6",
      "value": "galvanized",
      "text": "galvanized"
    },
    "weathered": {
      "id": "ad3446480d",
      "value": "weathered",
      "text": "brown finish"
    }
  }
}, {
  "id": "VP09",
  "name": "Viewpoint 09",
  "title": "Highway 3",
  "subtitle": "Looking Northwest",
  "lat": 49.583025,
  "lng": -114.115005,
  "orientation": 315,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position (10TM): 64399.8",
    "Northing Position (10TM): 5490387.0",
    "Elevation of Viewpoint Position (NAD83): 1210.0",
    "Height of Camera Above Ground (m): 1.7",
    "Date of Photography: 19 Feburary 2019 at 03:56 PM",
    "orientation of View: NW",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°",
  ],
  "viewpoints": {
    "existing": {
      "id": "7f6b2a94c8",
      "value": "existing",
      "text": "existing"
    },
    "galvanized": {
      "id": "657bca0726",
      "value": "galvanized",
      "text": "galvanized"
    },
    "weathered": {
      "id": "f929569cf5",
      "value": "weathered",
      "text": "brown finish"
    }
  }
}, {
  "id": "VP10",
  "name": "Viewpoint 10",
  "title": "222 Street",
  "subtitle": "Looking East",
  "lat": 49.576916842134672,
  "lng": -114.360714194505292,
  "orientation": 90,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position (10TM): 46197.9",
    "Northing Position (10TM): 5489590.8",
    "Elevation of Viewpoint Position (NAD83): 1295.8",
    "Height of Camera Above Ground (m): 1.7",
    "Date of Photography: 3 August 2019 at 2:16 PM",
    "orientation of View: E",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°",
  ],
  "viewpoints": {
    "existing": {
      "id": "a6b6f27d14",
      "value": "existing",
      "text": "existing"
    },
    "galvanized": {
      "id": "ec0562b3ad",
      "value": "galvanized",
      "text": "galvanized"
    },
    "weathered": {
      "id": "6f3c1feec3",
      "value": "weathered",
      "text": "brown finish"
    }
  }
}, {
  "id": "VP11",
  "name": "Viewpoint 11",
  "title": "Highway 22 (Cowboy Trail)",
  "subtitle": "Looking Southwest",
  "lat": 49.791210689868969,
  "lng": -114.158595359437442,
  "orientation": 202.5,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position (10TM): 704501.8",
    "Northing Position (10TM): 5519291.0",
    "Elevation of Viewpoint Position (NAD83): 1292.0",
    "Height of Camera Above Ground (m): 1.7",
    "Date of Photography: 2 June 2020 at 08:42 AM",
    "orientation of View: SSW",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°",
  ],
  "viewpoints": {
    "existing": {
      "id": "958daec58e",
      "value": "existing",
      "text": "existing"
    },
    "69kv": {
      "galvanized": {
        "id": "83b36572ca",
        "value": "69kv_galvanized",
        "text": "69kV galvanized"
      },
      "weathered": {
        "id": "280643fcd9",
        "value": "69kv_weathered",
        "text": "69kV brown finish"
      },
    },
    "240_69kv": {
      "galvanized": {
        "id": "db2887fa47",
        "value": "240_69kv_galvanized",
        "text": "240/69kV galvanized"
      },
      "weathered": {
        "id": "d4e2f6ac94",
        "value": "240_69kv_weathered",
        "text": "240/69kV brown finish"
      }
    }
  }
}, {
  "id": "VP12",
  "name": "Viewpoint 12",
  "title": "Highway 22 (Cowboy Trail)",
  "subtitle": "Looking Northeast",
  "lat": 49.749924779921564,
  "lng": -114.159020209185599,
  "orientation": 22.5,
  "icon": "../../assets/icons/cone.png",
  "data": [
    "Easting Position (10TM): 704645.1",
    "Northing Position (10TM): 5514700.6",
    "Elevation of Viewpoint Position (NAD83): 1320.5",
    "Height of Camera Above Ground (m): 1.7",
    "Date of Photography : 2 June 2020 at 09:09 AM",
    "orientation of View: NNE",
    "Horizontal Field of View: 124°",
    "Vertical Field of View: 55°",
  ],
  "viewpoints": {
    "existing": {
      "id": "2292c8348d",
      "value": "existing",
      "text": "existing"
    },
    "galvanized": {
      "id": "1bd358a417",
      "value": "galvanized",
      "text": "galvanized"
    },
    "weathered": {
      "id": "d59a7c5760",
      "value": "weathered",
      "text": "brown finish"
    }
  }
}]

export default Sites;