<div class="image-loader" [hidden]="beforeImageLoaded || afterImageLoaded">
  <mat-spinner></mat-spinner>
</div>

<div [hidden]="!beforeImageLoaded && !afterImageLoaded" #comparisonElement class="comparison__stage">

  <div class="comparison__slider">

    <div class="labels">
      <p>
        <fa-icon [icon]="faChevronLeft" size="1x"></fa-icon> proposed
      </p>
      <p>existing <fa-icon [icon]="faChevronRight" size="1x"></fa-icon>
      </p>
    </div>

  </div>

  <div class="comparison__container slide-image">
    <img [src]="getFirstImage()" (load)="beforeImageLoaded = true; comparisionFirstImageState = 'end'" [@trueviewImagesLoading]="comparisionFirstImageState" />
  </div>

  <div class="comparison__container fixed-image">
    <img [src]="getSecondImage()" (load)="afterImageLoaded = true; comparisionSecondImageState = 'end'" [@trueviewImagesLoading]="comparisionSecondImageState" />
  </div>

</div>
