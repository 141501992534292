import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faSearchPlus, faMoon, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router'

//models 
import Site, { Viewpoint } from '@models/site.model'

import { Store } from '@ngrx/store';
import { SiteState } from '@models/site.state.model';
import { AddSite } from '@app/actions/site.actions';

@Component({
  selector: 'app-trueviews-dialog',
  templateUrl: './trueviews-dialog.component.html',
  styleUrls: ['./trueviews-dialog.component.scss']
})
export class TrueviewsDialogComponent implements OnInit {

  faSearchPlus = faSearchPlus
  faMoon = faMoon
  faInfo = faInfo

  public galvanized: boolean = false
  private proposed: Viewpoint = this.site.viewpoints.weathered

  public images: string[] = []

  constructor(
    public dialogRef: MatDialogRef<TrueviewsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public site: Site,
    private router: Router,
    private store: Store<SiteState>) { }

  ngOnInit(): void {
    this.images = this.getComparisonImagesPath()
  }

  zoomTrueview(site: Site): void {
    this.store.dispatch(new AddSite(site))
    this.router.navigate(['map', site.id], { state: { data: site } })
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getImagePath(id: string): string {
    return `../../../assets/viewpoints/${id}/${id}.jpg`
  }

  getComparisonImagesPath(): string[] {
    return [
      this.getImagePath(this.proposed.id),
      this.getImagePath(this.site.viewpoints.existing.id)
    ]
  }

  public viewpointTypeToggled() {
    this.proposed = this.galvanized ? this.site.viewpoints.weathered : this.site.viewpoints.galvanized
    this.galvanized = !this.galvanized
    this.dialogRef.componentInstance.images = this.getComparisonImagesPath()
  }

}

