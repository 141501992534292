import { Component, OnInit, Input } from '@angular/core';
import Site from '@app/models/site.model';

import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-zoom-toggles',
  templateUrl: './zoom-toggles.component.html',
  styleUrls: ['./zoom-toggles.component.scss']
})
export class ZoomTogglesComponent implements OnInit {


  @Input() site:Site
  @Input() viewer

  public viewpoint11: boolean = false

  constructor(private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.viewpoint11 = this.site.id === 'VP11' ? true : false
  }

  showSnakbar(state) {
    this._snackBar.open(`You are viewing the ${state} TrueView™`, 'dismiss', {
      duration: 1500,
    });
  }

}
