import { Injectable } from '@angular/core';

import ExistingTransmissionLines from '@assets/layers/existing-transmission-lines.geo.json'
import Substations from '@assets/layers/substations.geo.json'
import ProposedSubstations from '@assets/layers/proposed_substations.geo.json'
import Residences from '@assets/layers/residences.geo.json'
import ProposedRoutes from '@assets/layers/proposed-routes.geo.json'
import StudyArea from '@assets/layers/study_area.geo.json'

import * as L from "leaflet"
import { Layer } from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export default class LayersService {

  public readonly existingTransmissionLines: Layer = L.geoJSON(ExistingTransmissionLines as any, {
    onEachFeature: (feature, layer) => {

      if (layer instanceof L.Polyline) {
        layer.setStyle({
          color: feature.properties.data.color,
          weight: feature.properties.data.weight,
          opacity: 1,
          dashArray: feature.properties.data.dashArray ? '6 4 1 4' : '0',
        }).bindTooltip('Existing Transmission Line',
          {
            permanent: false,
            opacity: 0.9,
            direction: "auto",
            className: "circuit-labels"
          }).openTooltip()
      }
    }
  })

  public readonly proposedRoutes: Layer = L.geoJSON(ProposedRoutes as any, {
    onEachFeature: (feature, layer) => {

      let label: string = `${feature.properties.display}`;

      if (layer instanceof L.Polyline) {

        layer.setStyle({
          color: feature.properties.data.color,
          stroke: true,
          weight: feature.properties.data.weight,
          opacity: 1,
          dashArray: feature.properties.data.dashArray ? '9' : '0',
        }).bindTooltip(label,
          {
            permanent: false,
            opacity: 0.9,
            direction: "auto",
            className: "route-labels"
          }).openTooltip()
      }
    }
  })

  public readonly substations: Layer = L.geoJSON(Substations as any, {
    pointToLayer: (feature, latlng) => {
      let label: string = feature.properties.NAME
      let direction: L.Direction = feature.properties.DIRECTION
      let className: string = feature.properties.CLASSNAME
      return L.circleMarker(latlng, {
        radius: 4,
        fillColor: "#0000ff",
        color: "#fff",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8
      }).bindTooltip(label,
        {
          permanent: true,
          opacity: 1,
          direction,
          className
        }).openTooltip()
    }
  })

  public readonly proposedSubstations: Layer = L.geoJSON(ProposedSubstations as any, {
    onEachFeature: (feature, layer) => {
      let label = `Proposed Substation - ${feature.properties.SUBSTATION} `;
      if (layer instanceof L.Polyline) {
        layer.setStyle({
          stroke: true,
          color: '#fff',
          dashArray: '1',
          weight: 3,
          opacity: 1,
          fillOpacity: 1,
          fillColor: "#f33"
        }).bindTooltip(label,
          {
            permanent: false,
            opacity: 0.9,
            direction: "auto",
            className: "proposed-substations-labels"
          }).openTooltip()
      }
    }
  })

  public readonly studyArea: Layer = L.geoJSON(StudyArea as any, {
    onEachFeature: (feature, layer) => {
      let label = feature.properties.display
      if (layer instanceof L.Polyline) {
        layer.setStyle({
          color: feature.properties.data.color,
          dashArray: feature.properties.data.dashArray ? '5' : '0',
          weight: feature.properties.data.weight,
          opacity: 0.9,
          fill: false
        })
      }
    }
  })

  public readonly residences: Layer = L.geoJSON(Residences as any, {
    pointToLayer: (feature, latlng) => {
      return L.circleMarker(latlng, {
        radius: 3,
        fillColor: "#ffd700",
        color: "#fff",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.75
      })
    }
  })

  public readonly layers: Layer[] = [
    this.existingTransmissionLines,
    this.substations,
    this.proposedSubstations,
    // this.residences,
    this.proposedRoutes,
    this.studyArea,
  ]

}
