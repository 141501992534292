import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faSearchPlus, faMoon, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router'

//models 
import Site, { Viewpoint } from '@models/site.model'

import { Store } from '@ngrx/store';
import { SiteState } from '@models/site.state.model';
import { AddSite } from '@app/actions/site.actions';


@Component({
  selector: 'app-viewpoint11-dialog',
  templateUrl: './viewpoint11-dialog.component.html',
  styleUrls: ['./viewpoint11-dialog.component.scss']
})
export class Viewpoint11DialogComponent implements OnInit {

  faSearchPlus = faSearchPlus
  faMoon = faMoon
  faInfo = faInfo

  public galvanized: boolean = false
  public images_240_69kv: Array<string> = []
  public images_69kv: Array<string> = []

  constructor(
    public dialogRef: MatDialogRef<Viewpoint11DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public site: Site,
    private router: Router,
    private store: Store<SiteState>) { }

  ngOnInit(): void {
    let type = this.galvanized ? 'weathered' : 'galvanized'
    this.images_240_69kv = this.getComparisonImages('240_69kv', type)
    this.images_69kv = this.getComparisonImages('69kv', type)
  }

  zoomTrueview(site: Site): void {
    this.store.dispatch(new AddSite(site))
    this.router.navigate(['/map', site.id], { state: { data: site } })
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private getImagePath(id: string) {
    return `../../../assets/viewpoints/${id}/${id}.jpg`
  }

  private getComparisonImages(voltage: string, type: string): Array<string> {
    return [
      this.getImagePath(this.site.viewpoints[`${voltage}`][`${type}`].id),
      this.getImagePath(this.site.viewpoints.existing.id)
    ]
  }

  public viewpointTypeToggled() {
    let type = this.galvanized ? 'weathered' : 'galvanized'
    this.galvanized = !this.galvanized
    this.dialogRef.componentInstance.images_69kv = this.getComparisonImages('69kv', type)
    this.dialogRef.componentInstance.images_240_69kv = this.getComparisonImages('240_69kv', type)

  }
}

