import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { faPlay, faInfo } from '@fortawesome/free-solid-svg-icons'
import { MapLegendComponent } from '@components/map-legend/map-legend.component'
import { MatDialog } from '@angular/material/dialog'
import { VideoDialogComponent } from '@components/video-dialog/video-dialog.component';

@Component({
  selector: 'map-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public readonly faPlay = faPlay
  public readonly faInfo = faInfo

  @Output() infoDialogOpened = new EventEmitter<boolean>(false)

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void { }

  public playVideo() {
    const dialogRef = this.dialog.open(VideoDialogComponent, {
      data: '../../../assets/video/altalink-video.mp4',
      width: '55vw',
      id:'video-dialog-id',
      hasBackdrop: true
    });
  }

  public openMapLegendDialog() {
    this.infoDialogOpened.emit(true)
    this.dialog.open(MapLegendComponent, {
      position: { right: '25px' },
      height: 'auto',
      hasBackdrop: false
    })
  }

}
