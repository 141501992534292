import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsModule } from '@settings/settings.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from '@app/app-routing.module';
import { TrueviewsDialogComponent } from '@components/trueviews-dialog/trueviews-dialog.component';
import { CalibrationDialogComponent } from '@components/calibration-dialog/calibration-dialog.component';
import { Ng5SliderModule } from 'ng5-slider';
import { HeaderComponent } from '@components/header/header.component';
import { FooterComponent } from '@components/footer/footer.component';
import { ComparisonModule } from '@components/image-comparison/image-comparison.module'

import { AngularSvgIconModule } from 'angular-svg-icon';

// viewpoints dialogs
import { VP01DialogComponent } from '@components/viewpoints-dialogs/viewpoint01-dialog.component'
import { Viewpoint11DialogComponent } from '@components/viewpoints-dialogs/viewpoint11-dialog.component';
import { MapLegendComponent } from '@components/map-legend/map-legend.component'

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ZoomTogglesComponent } from './zoom-toggles/zoom-toggles.component'
import { VideoDialogComponent } from '@components/video-dialog/video-dialog.component'

@NgModule({
  declarations: [
    TrueviewsDialogComponent,
    CalibrationDialogComponent,
    VP01DialogComponent,
    Viewpoint11DialogComponent,
    HeaderComponent,
    FooterComponent,
    MapLegendComponent,
    ZoomTogglesComponent,
    VideoDialogComponent
  ],
  imports: [
    CommonModule,
    SettingsModule,
    AppRoutingModule,
    FontAwesomeModule,
    Ng5SliderModule,
    ComparisonModule,
    AngularSvgIconModule.forRoot(),
    LeafletModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ZoomTogglesComponent,
    TrueviewsDialogComponent,
    VP01DialogComponent,
    Viewpoint11DialogComponent,
    MapLegendComponent,
    VideoDialogComponent
  ],
  providers: [
    HeaderComponent,
    FooterComponent,
    ZoomTogglesComponent,
    VP01DialogComponent,
    Viewpoint11DialogComponent,
    TrueviewsDialogComponent,
    MapLegendComponent,
    VideoDialogComponent
  ],
  entryComponents: [
    TrueviewsDialogComponent
  ],
})
export class ComponentsModule {

  constructor() { }
}
