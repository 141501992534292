import { Component, OnDestroy } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

import { Store } from '@ngrx/store';
import { MapState } from '@models/map.state.model';
import { ResetMap } from '@app/actions/map.actions';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  animations: [
    trigger('loading', [
      state('start', style({
        opacity: 0
      })),
      state('end', style({
        opacity: 1
      })),
      transition('start => end', [animate('0.5s')])
    ])
  ]
})
export class HomepageComponent implements OnDestroy{

  constructor(private store: Store<MapState>) {}
  
  ngOnDestroy():void {
    this.store.dispatch(new ResetMap())
  }
}
