<figure>
  <img class="altalink-logo" src="../../../assets/logos/altalink-logo-dark.png" alt="altalink-logo">
</figure>
<div>
  <h3>project overview</h3>
  <h5>click onto blue view-cones to see simulations of proposed structure options. Use <img src="../../../assets/layers-icons/map-layers.png" alt="map layer icon" width="35"> icon to toggle between structures layers and map types.</h5>
</div>
<fa-icon matTooltip="View a video, cycling through all structure options under consideration." [icon]="faPlay" size="2x" (click)="playVideo()"></fa-icon>
<fa-icon matTooltip="Show map legend" [icon]="faInfo" size="2x" (click)="openMapLegendDialog()"></fa-icon>
<figure>
  <img class="truescape-logo" src="../../../assets/logos/truescape-logo-dark.png" alt="truescape-logo">
</figure>
