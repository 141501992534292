import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-map-legend',
  templateUrl: './map-legend.component.html',
  styleUrls: ['./map-legend.component.scss']
})
export class MapLegendComponent {

  icons  = {
    'Proposed 240kV Transmission Line Route': 'proposed-240kv-transmission-line-route.svg',
    'Proposed 69kV/240kV Double Circuit or 69kV Single Circuit Transmission Line Route': '240kv-double-circuit-or-69kv-single-circuit-transmission-line-route.svg',
    'Proposed Re-use of Existing Transmission Line Route': 'proposed-reuse-existing-transmission-line-route.svg',
    'Proposed 170L and 412L Replacement with 240kV Transmission Line Route':'proposed-170l-and-412l-replacemeny-with-240kv.svg',
    'Proposed 240kV/138kV Double Circuit Transmission Line Route': 'propsed-240kv-138kv-double-circuit-transmission-line.svg',
    'Proposed 69kV Transmission Line Route': 'proposed-69kv-transmission-line-route.svg',
    'Proposed Salvage of existing transmission line route': 'proposed-salvage.svg',
    'Proposed 69kV/240kV Double Circuit Transmission Line Route': 'proposed-240kv-69kv-double-circuit-transmission-line-route.svg',
    'Study Area': 'study-area.svg',
    'Expanded Study Area': 'extended-study-area.svg',
    'Viewpoints': 'cone.svg',
    'No Longer Proposed': 'cone-vp01.svg',
    'Toggle Map Layers': 'map-layers.png',
    'Proposed Substation':'proposed-substation.svg',
    'Existing Subststion':'existing-substations.svg',
    'Residence':'residences.svg',
    'Existing Transmission Line':'existing-transimission-line.svg'

  }

  constructor(public dialogRef: MatDialogRef<MapLegendComponent>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }


}




