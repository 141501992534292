import { Component, OnInit, NgZone, SimpleChanges } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'

import * as L from "leaflet"
import { Layer } from 'leaflet';

// importing models
import Site from '@models/site.model'

import { Store } from '@ngrx/store';
import { MapState } from '@models/map.state.model';
import { AddMap, ResetMap } from '@app/actions/map.actions';

import { SitesService } from '@services/sites.service'
import LayersService from '@services/layers.service';
import { MarkersService } from '@app/services/markers.service';
import { Subscription } from 'rxjs';

import { MAPTYPES } from '@app/models/map.types';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  public layers: Layer[] = []
  public sites: Array<Site>
  public map: L.Map
  public mapState: MapState

  private SitesSubscription: Subscription

  private readonly tileLayerOptionsObj = {
    detectRetina: true,
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    maxZoom: 20,
    minZoom: 0
  }

  private readonly hybrid = L.tileLayer(MAPTYPES.HYBRID, this.tileLayerOptionsObj)
  private readonly street = L.tileLayer(MAPTYPES.STREET, this.tileLayerOptionsObj)
  private readonly satellite = L.tileLayer(MAPTYPES.SATELLITE, this.tileLayerOptionsObj)
  private readonly terrain = L.tileLayer(MAPTYPES.TERRAIN, this.tileLayerOptionsObj)

  public options: L.MapOptions = {
    layers: [this.hybrid, this.satellite, this.terrain, this.street],
    zoom: 11,
    zoomControl: true,
    center: L.latLng(49.641807, -114.181797)
  }

  public layersControl = {
    baseLayers: {
      'street map': this.street,
      'terrain map': this.terrain,
      'satellite map': this.satellite,
      'hybrid map': this.hybrid
    },
    overlays: {
      'Existing Substations': this.layersService.substations,
      'Proposed Substations': this.layersService.proposedSubstations,
      'Existing Transmission Line': this.layersService.existingTransmissionLines,
      'Proposed Routes': this.layersService.proposedRoutes,
      Residences: this.layersService.residences,
      'Study Area': this.layersService.studyArea
    }
  }

  constructor(
    public dialog: MatDialog,
    private store: Store<any>,
    private zone: NgZone,
    private sitesService: SitesService,
    private layersService: LayersService,
    private markersService: MarkersService) {
  }


  ngOnInit(): void {
    this.getSites()
    this.store.select('map').subscribe(mapState => this.mapState = mapState)
  }


  private getSites(): void {
    this.SitesSubscription = this.sitesService.getSites().subscribe(sites => this.sites = sites)
  }

  public onMapLoaded(map: L.Map): void {
    this.layers.push(...this.layersService.layers)
    for (let site of this.sites) {
      let marker = this.markersService.addMarkerToMap(site)
      marker.on('click', () => {
        this.zone.run(() => {
          this.openViewpointDialog(site)
        })
      })
      this.layers.push(marker)
    }
  }

  private async openViewpointDialog(site: Site): Promise<any> {
    let mapState = await this.getMapState()
    this.store.dispatch(new AddMap(mapState))
    this.sitesService.openSiteTrueviewsDialog(site)
  }

  private getMapCurrentCenter() {
    return [
      this.map.getCenter().lat,
      this.map.getCenter().lng
    ]
  }

  async getMapState(): Promise<MapState> {
    return new Promise(resolve => {
      resolve({
        center: this.getMapCurrentCenter(),
        zoom: this.map.getZoom(),
        animate: false
      })

    })
  }

  public resetView(): void {
    this.map.flyTo([49.641807, -114.181797], 11, {
      animate: true,
      duration: 1.5
    })
    this.store.dispatch(new ResetMap())
  }

  ngOnDestroy(): void {
    this.SitesSubscription.unsubscribe()

  }

}
