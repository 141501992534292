<mat-tab-group mat-align-tabs="start" animationDuration="1000ms">
  <mat-tab label="240/69kV Single Circuit">
    <mat-dialog-content class="image-comparison">
      <app-image-comparison *ngIf="images_240_69kv" [images]='images_240_69kv'></app-image-comparison>
    </mat-dialog-content>
  </mat-tab>
  <mat-tab label="69kV Single Circuit">
    <mat-dialog-content class="image-comparison">
      <app-image-comparison *ngIf="images_69kv" [images]='images_69kv'></app-image-comparison>
    </mat-dialog-content>
  </mat-tab>
</mat-tab-group>

<section class="d-flex justify-content-between align-items-center mt-3">

  <div>
    <img src="../../../assets/small-vp-maps/{{site.id}}.jpg" alt="small map for viewpoint" width="200" height="200">
  </div>

  <div mat-dialog-title class="trueview-dialog-header">
    <div class="trueview-comparision-title">
      <h2 class="text-uppercase font-weight-bold text-center">{{site.name}}</h2>
      <h3 class="text-capitalize font-weight-light text-center">{{site.title}}</h3>
      <h6 class="text-capitalize font-weight-light text-center">{{site.subtitle}}</h6>
    </div>
  </div>
  <div class="site-data">
    <div *ngFor="let data of site.data">
      {{data}}
    </div>
  </div>
  <mat-dialog-actions class="d-flex flex-column justify-content-between align-items-center">
    <div class="mb-5">
      <span color="warn">Brown Finish </span>
      <mat-slide-toggle [color]="'warn'" [checked]="galvanized" [disabled]="false" (toggleChange)="viewpointTypeToggled()">
        <span> Galvanized</span>
      </mat-slide-toggle>
    </div>
    <div>
      <button mat-raised-button (click)="zoomTrueview(site)">
        <fa-icon [icon]="faSearchPlus" size="1x"></fa-icon> Zoom
      </button>
      <button mat-raised-button mat-dialog-close tabindex="-1" class=" mr-1 trueview-dismiss-btn">Close</button>
    </div>
  </mat-dialog-actions>
</section>
