import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'dialog-overview-example-dialog',
  styles:[`
      h3, small {
        font-family: Oswald;
      }
  `],
  template: `
  <div class="text-center">
      <small class="text-mute">Viewpoint 01 - Township Road 92 (Wildcat Creek Valley)</small>
      <h3 class="text-danger"><fa-icon [icon]="faInfoCircle" size="1x"></fa-icon> This viewpoint has been removed as this location is no longer being considered.</h3>
  </div>
  `,
})
export class VP01DialogComponent {

  faInfoCircle = faInfoCircle

  constructor(
    public dialogRef: MatDialogRef<VP01DialogComponent>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}