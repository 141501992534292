<h1 mat-dialog-title class="text-center">Map Legend</h1>

<div mat-dialog-content>
  <ng-container *ngFor="let icon of icons | keyvalue">
    <mat-list>
      <img width="25" height="25" src="../../../assets/layers-icons/{{icon.value}}" alt="map legend icon">&nbsp;&nbsp;{{icon.key}}
    </mat-list>
  </ng-container>
  <div mat-dialog-actions>
    <button mat-raised-button mat-dialog-close color="warn">Close</button>
  </div>
</div>
