<figure>
  <img src="../../assets/logos/altalink-logo-dark.png" alt="altalink-logo">
</figure>

<div class="center-section">
  <img src="../../assets/images/header-bottom-bar.png" alt="top bar image">
  <h1>CHAPEL ROCK TO PINCHER CREEK AREA <br>TRANSMISSION DEVELOPMENT</h1>
  <div>
    <p>A new substation, named Chapel Rock, which will connect to the Alberta/British Columbia intertie transmission line located west of Highway 22 and north of Highway 3.</p>
    <p>A new transmission line connecting the Chapel Rock Substation to either the existing Goose Lake or Castle Rock Ridge substations, both located north of Pincher Creek.</p>
  </div>
  <p>The final location of the new substation and transmission line has not been determined or approved.
  </p>
</div>
<div matTooltip="Please press 'Continue' to view the project simulations. At some stage we may take you through a one-time wizard identifying the size of your computer screen. This will allow for an optimum viewing experience and depiction of the project simulations at correct size." routerLinkActive="active" routerLink="/map">continue</div>
<img src="../../assets/images/footer-top-bar.png" alt="bottom bar image">

<figure>
  <img class="truescape-logo" src="../../assets/logos/truescape-logo-dark.png" alt="truescape-logo">
</figure>
